import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Link, Box, LinkBox, Image } from "@quarkly/widgets";
import QuarklycommunityKitMobileSidePanel from "./QuarklycommunityKitMobileSidePanel";
const defaultProps = {
	"min-width": "100px",
	"display": "flex",
	"margin": "0px 0px 56px 0px",
	"position": "sticky",
	"top": "10px",
	"quarkly-title": "Header",
	"justify-content": "space-between",
	"width": "100%",
	"mix-blend-mode": "normal",
	"z-index": "200",
	"sm-padding": "0px 24px 0px 24px"
};
const overrides = {
	"link": {
		"kind": "Link",
		"props": {
			"margin": "0px 0px 0px 0px",
			"text-transform": "uppercase",
			"letter-spacing": "1px",
			"font": "--lead",
			"text-decoration-line": "initial",
			"href": "/index",
			"md-font": "--base",
			"hover-color": "--primary",
			"transition": "color 0.2s --transitionTimingFunction-easeInOut 0s",
			"children": "Artem Zhigalin",
			"color": "--darkL2"
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex"
		}
	},
	"quarklycommunityKitMobileSidePanel": {
		"kind": "QuarklycommunityKitMobileSidePanel",
		"props": {
			"animFunction": "ease-in-out",
			"animDuration": "0.3s",
			"breakpoint": "sm"
		},
		"overrides": {
			"Overlay": {
				"props": {
					"sm-display": "none"
				}
			}
		}
	},
	"quarklycommunityKitMobileSidePanelOverride": {
		"kind": "Override",
		"props": {
			"slot": "Button Text",
			"text-transform": "uppercase",
			"letter-spacing": "1px",
			"font": "--lead",
			"text-decoration-line": "initial",
			"md-font": "--base",
			"color": "--darkL2",
			"children": "Menu"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride1": {
		"kind": "Override",
		"props": {
			"slot": "Children",
			"sm-display": "flex",
			"sm-flex-direction": "column",
			"width": "100%",
			"sm-padding": "18px 32px 18px 0px"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride2": {
		"kind": "Override",
		"props": {
			"slot": "Children :open",
			"sm-display": "flex",
			"sm-flex-direction": "column",
			"sm-padding": "18px 32px 18px 0px"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride3": {
		"kind": "Override",
		"props": {
			"slot": "Content",
			"sm-margin": "16px 0px 0px 0px",
			"sm-box-shadow": "none",
			"sm-border-style": "solid",
			"padding": "0px 0px 0px 0px",
			"background": "transparent",
			"sm-border-width": "1px",
			"sm-border-color": "--color-lightD2"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"margin": "0px 16px 0px auto",
			"text-transform": "uppercase",
			"letter-spacing": "1px",
			"font": "--lead",
			"text-decoration-line": "initial",
			"href": "http://blog.artemzhigalin.ru/",
			"md-font": "--base",
			"hover-color": "--primary",
			"transition": "color 0.2s --transitionTimingFunction-easeInOut 0s",
			"children": "Blog",
			"color": "--darkL2",
			"sm-margin": "8px 0px 24px auto",
			"display": "none"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"margin": "0px 16px 0px auto",
			"text-transform": "uppercase",
			"letter-spacing": "1px",
			"font": "--lead",
			"text-decoration-line": "initial",
			"md-font": "--base",
			"hover-color": "--primary",
			"transition": "color 0.2s --transitionTimingFunction-easeInOut 0s",
			"children": "Works",
			"color": "--darkL2",
			"sm-margin": "0px 0px 24px auto",
			"href": "/works"
		}
	},
	"link3": {
		"kind": "Link",
		"props": {
			"margin": "0px 0px 0px auto",
			"text-transform": "uppercase",
			"letter-spacing": "1px",
			"font": "--lead",
			"text-decoration-line": "initial",
			"href": "https://artemzhigalin.ru/src/CV-Zhigalin-Artem-ru.pdf",
			"md-font": "--base",
			"color": "--darkL2",
			"hover-color": "--primary",
			"transition": "color 0.2s --transitionTimingFunction-easeInOut 0s",
			"children": "CV",
			"sm-margin": "0px 0px 18px auto"
		}
	},
	"linkbox1": {
		"kind": "Link",
		"props": {
			"href": "/index",
			"title": "Designer Artem Zhigalin",
			"sm-margin": "0px 0px 0px 0px"
		}
	},
	"image1": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-32-trans.png?v=2022-06-20T13:26:43.913Z",
			"srcSet": "https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-64-trans.png?v=2022-06-21T07:11:54.851Z 2x",
			"display": "block",
			"width": "32px",
			"height": "32px"
		}
	}
};

const Header2 = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Box {...rest}>
		<LinkBox {...override("linkbox1")}>
			<Image {...override("image1")} />
		</LinkBox>
		<Box {...override("box")}>
			<QuarklycommunityKitMobileSidePanel {...override("quarklycommunityKitMobileSidePanel")}>
				<Override {...override("quarklycommunityKitMobileSidePanelOverride")} />
				<Override {...override("quarklycommunityKitMobileSidePanelOverride1")} />
				<Override {...override("quarklycommunityKitMobileSidePanelOverride2")} />
				<Override {...override("quarklycommunityKitMobileSidePanelOverride3")} />
				<Link {...override("link1")} />
				<Link {...override("link2")} />
				<Link {...override("link3")} />
			</QuarklycommunityKitMobileSidePanel>
		</Box>
		{children}
	</Box>;
};

Object.assign(Header2, { ...Box,
	defaultProps,
	overrides
});
export default Header2;