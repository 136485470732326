import React from "react";
import { useOverrides, Override, SocialMedia } from "@quarkly/components";
import { Text, Link, Section } from "@quarkly/widgets";
const defaultProps = {
	"quarkly-title": "Footer",
	"inner-width": "100%",
	"sm-margin": "100px 0 0 0",
	"padding": "0px 0 0px 0",
	"margin": "12px 0 0 0"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"color": "--grey",
			"sm-margin": "0px 0px 8px 0px",
			"children": <>
				© Artem Zhigalin |{" "}
				<Link href="mailto:psycholcycle@gmail.com" color="--grey" text-decoration-line="initial">
					psycholcycle@gmail.com
				</Link>
			</>
		}
	},
	"socialMedia": {
		"kind": "SocialMedia",
		"props": {
			"margin": "0px 0px 0px 0px",
			"justify-content": "flex-end",
			"sm-margin": "0px 0px 0px -8px",
			"facebook": "https://www.facebook.com/psycholcycle/",
			"twitter": "https://twitter.com/psycholcycle",
			"linked-in": "https://www.linkedin.com/in/art%C3%A9m-zhig%C3%A1lin-47853555/",
			"telegram": "https://t.me/psycholcycle",
			"github": "https://github.com/psycholcycle"
		}
	},
	"socialMediaOverride": {
		"kind": "Override",
		"props": {
			"slot": "link",
			"border-radius": "50%",
			"color": "--grey",
			"margin": "0 8px 0 8px",
			"width": "32px",
			"height": "32px",
			"align-items": "center",
			"display": "flex",
			"justify-content": "center",
			"hover-transition": "background-color 0.2s ease-in-out 0s",
			"transition": "background-color 0.2s ease-in-out 0s",
			"padding": "0px 0px 0px 0px",
			"background": "transparent",
			"hover-background": "--color-lightD2",
			"hover-color": "--primary"
		}
	},
	"socialMediaOverride1": {
		"kind": "Override",
		"props": {
			"slot": "icon",
			"border-width": "0px",
			"size": "38px",
			"border-radius": "50px",
			"width": "16px",
			"height": "16px",
			"color": "inherit"
		}
	},
	"SectionContent": {
		"props": {
			"max-width": "2560px",
			"sm-padding": "0px 24px 0px 0px"
		}
	}
};

const Footer = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override
			slot="SectionContent"
			max-width="1920px"
			width="100%"
			display="grid"
			grid-template-columns="repeat(2, 1fr)"
			grid-gap="24px"
			md-grid-template-columns="1fr 1fr"
			justify-items="stretch"
			md-grid-gap="0px 24px "
			align-items="center"
			sm-grid-template-columns="1fr"
			sm-justify-items="start"
		/>
		<Text {...override("text")} />
		<SocialMedia {...override("socialMedia")}>
			<Override {...override("socialMediaOverride")} />
			<Override {...override("socialMediaOverride1")} />
		</SocialMedia>
		{children}
	</Section>;
};

Object.assign(Footer, { ...Section,
	defaultProps,
	overrides
});
export default Footer;